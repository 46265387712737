import React from 'react';
import TagState from '../Types/TagState'
import DateService from '../Services/DateService'
import Area from '../Types/Area'

interface PropsType {
	tags: TagState[];
	evacuated: boolean;
	minimumDate: Date;
	selectedAssemblyPoint?: number;
	availableAreas: { [index: number]: Area};
  }

class TagTable extends React.Component<PropsType> {
	render() {
		let areaTitle = "Last Area(s)";
		if (this.props.evacuated) areaTitle = "Last Assembly Point";
		return (<>
			  <table>
				<thead>
				  <tr>
					<td>Name</td>
					<td>Last Update</td>
					<td>{areaTitle}</td>
				  </tr>
				</thead>
				<tbody>
				{this.props.tags.map((row) => {
				  if (this.props.evacuated && row.lastAssemblyPoint === 0) return;
				  if (!this.props.evacuated && row.lastAssemblyPoint !== 0) return;
				  if (this.props.minimumDate > row.timestamp) return;
				  if (this.props.selectedAssemblyPoint !== undefined && this.props.selectedAssemblyPoint !== 0 && this.props.selectedAssemblyPoint !== row.lastAssemblyPoint) return;
				  
				  let name = row.name;
				  if (name == null || name.length === 0) name = "ID"+row.serialNumber.toString();
				  
				  let age = row.age+" seconds ago";
				  if (row.age > 59) {
					if (row.age > 3600){
					  age = DateService.formatDate(new Date(row.timestamp));
					} else {
					  age = Math.round(row.age / 60)+" minutes ago";
					}
				  }
	
				  let availableAreas = this.props.availableAreas;
				  let areas = row.areas;
				  if (row.lastAssemblyPoint !== 0) areas = [row.lastAssemblyPoint];
				  let niceAreas = areas.reduce(function(pV:string[], cV:number, cI){
					if (cV in availableAreas) {
					  pV.push(availableAreas[cV].name);
					} else {
					  pV.push("ID:"+cV.toString());
					}
					return pV;
				  }, []);
				  if (niceAreas.length == 0) niceAreas.push("-");
	
				  return <tr><td>{name}</td><td>{age}</td><td>{niceAreas.join(", ")}</td></tr>
				})}
				</tbody>
			  </table>
		  </>);
	  }
}


export default TagTable;