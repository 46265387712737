import React from 'react';
import Credentials from '../Types/Credentials'


interface CallbackType { (credentials: Credentials): Promise<string|null> }


interface IProps {
	callback: CallbackType;
	hasChannel: boolean;
}

interface IState {
	clientId: number|null;
	clientSecret: string|null;
	accountId: number|null;
	siteId: number|null;
	lastError: string;
}

class SignIn extends React.Component<IProps, IState> {
	clientIdChanged = (event: React.FormEvent<HTMLInputElement>) => {
		let clientId = parseInt(event.currentTarget.value);
		this.setState({
			clientId: isNaN(clientId)? null:clientId
		});
	};

	clientSecretChanged = (event: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			clientSecret: event.currentTarget.value
		});
	};

	accountIdChanged = (event: React.FormEvent<HTMLInputElement>) => {
		let accountId = parseInt(event.currentTarget.value);
		this.setState({
			accountId: isNaN(accountId)? null:accountId
		});
	};

	siteIdChanged = (event: React.FormEvent<HTMLInputElement>) => {
		let siteId = parseInt(event.currentTarget.value);
		this.setState({
			siteId: isNaN(siteId)? null:siteId
		});
	};
	
	formSubmitted = async (event: React.FormEvent<HTMLElement>) => {
		event.preventDefault();
		let error = null;
		if (this.state.clientId === null) error = "Client ID is not in valid format";
		if (this.state.clientSecret === null) error = "Client secret is not in valid format";
		if (this.state.accountId === null) error = "Account ID is not in valid format";
		if (this.state.siteId === null) error = "Site ID is not in valid format";

		this.setState({
			lastError: error || ""
		});
		
		if (error !== null) return;

		error = await this.props.callback({
			clientId: this.state.clientId!,
			clientSecret: this.state.clientSecret!,
			accountId: this.state.accountId!,
			siteId: this.state.siteId!
		});
		if (error !== null){
			console.log(error);
			this.setState({
				lastError: "Error while connecting: " + error
			});
		} else {
			window.localStorage.setItem("clientId", this.state.clientId!.toString());
			window.localStorage.setItem("clientSecret", this.state.clientSecret!);
			window.localStorage.setItem("accountId", this.state.accountId!.toString());
			window.localStorage.setItem("siteId", this.state.siteId!.toString());
		}
	};

	  // Before the component mounts, we initialise our state
	componentWillMount() {
		let clientId = parseInt(window.localStorage.getItem("clientId") ?? "");
		let clientSecret = window.localStorage.getItem("clientSecret") ?? "";
		let accountId = parseInt(window.localStorage.getItem("accountId") ?? "");
		let siteId = parseInt(window.localStorage.getItem("siteId") ?? "");
		this.setState({
			clientId: isNaN(clientId) ? null: clientId,
			clientSecret: clientSecret,
			accountId: isNaN(accountId) ? null: accountId,
			siteId: isNaN(siteId) ? null: siteId
		});
	}

	render() {
		if (this.props.hasChannel) return (<p>Connecting to Noccela cloud..</p>);

		return (<form onSubmit={this.formSubmitted}>
			<p id="error">{this.state.lastError}</p>
			<p>Enter client ID</p>
			<input type="password" defaultValue={this.state.clientId || ""} onChange={this.clientIdChanged} />
			<p>Enter client secret</p>
			<input type="password" defaultValue={this.state.clientSecret || ""} onChange={this.clientSecretChanged} />
			<p>Enter account ID</p>
			<input type="string" defaultValue={this.state.accountId || ""} onChange={this.accountIdChanged} />
			<p>Enter site ID</p>
			<input type="string" defaultValue={this.state.siteId || ""} onChange={this.siteIdChanged} />
			<button onClick={this.formSubmitted}>Sign In</button>
		  </form>);
	  }
}


export default SignIn;