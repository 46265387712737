import React from 'react';
import './App.css';
import logo from '../Resources/logo.svg'
import {EventChannel, getToken} from '@noccela/ncc-cloud-integration';
import TagTable from './TagTable'
import SignIn from './SignIn'
import TagState from '../Types/TagState'
import DateService from '../Services/DateService'
import SwitchSelector from "react-switch-selector";
import Area from '../Types/Area'
import Credentials from '../Types/Credentials'


const maxHoursBeforeEvacuation:number = 12;

interface IProps {
}

interface IState {
  tagCache: TagState[];
  evacuationStarted: Date | null;
  appStarted: boolean;
  selectedAssemblyPoint: number;
  areas: { [index: number]: Area};
  channel:any;
}

class App extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  updateAge = () => {
    let nowTimestamp = new Date().getTime();
    let tagCache:TagState[] = [...this.state.tagCache];
    for (let tagCacheRow of tagCache) {
      tagCacheRow.age = Math.floor((nowTimestamp-tagCacheRow.timestamp.getTime())/1000);
    }
    
    this.setState({
      tagCache: tagCache
    });
  }

  sortTags = (tags:TagState[]) => {
    tags.sort(function(a, b){
      if (a.lastAssemblyPoint > b.lastAssemblyPoint) return 1;
      if (a.lastAssemblyPoint < b.lastAssemblyPoint) return -1;
      if (a.age > b.age) return 1;
      if (a.age < b.age) return -1;
      return 0;
    });
  }

  isEvacuated = (lastEvacuationPoint:number, areas:number[], timestamp:Date):number => {
    if (this.state.evacuationStarted === null) return 0;
    if (this.state.evacuationStarted! > timestamp) return 0;
    for (let area of areas) {
      if (area in this.state.areas){
        if (this.state.areas[area].evacuationPoint) return area;
      }
    }
    return lastEvacuationPoint;
  }

  startEvacuation = () => {
    this.setState({
      evacuationStarted: new Date()
    });
  }

  selectedAssemblyPointChanged = (selectedAssemblyPoint:any) => {
    this.setState({
      selectedAssemblyPoint: selectedAssemblyPoint
    });
  };

  connectToNoccela = async (credentials:Credentials):Promise<string|null> => {
    let channel = new EventChannel(credentials.accountId, credentials.siteId);
    this.setState({
      channel: channel
    });
    
    async function fetchToken() {
      let { accessToken, expiresIn } = await getToken(credentials.clientId, credentials.clientSecret);
      return accessToken;
    }
  
    try {
      await channel.connectPersistent(fetchToken);
      await channel.registerSiteInformation(this.siteInformationCallback);
      await channel.registerInitialTagState(this.tagStateCallback);
      await channel.registerTagDiffStream(this.tagDiffCallback);
    } catch(e){
      channel.close();
      this.setState({
        channel: null
      });
      return (e as Error).message;
    }
    return null;
  }

  siteInformationCallback = (err:object, payload:any) => {
    let itemTypesForAreas:number[] = [4,5,6];

    let floors = payload["layout"]["floors"];
    let areas:{ [index: number]: Area}  = {}
    for (let floor of floors) {
      for (let layer of floor["layers"]) {
        for (let item of layer["items"]) {
          if (itemTypesForAreas.includes(item.type)){
            //console.log(item);
            areas[item["permanentId"]] = {
              name: item["name"],
              evacuationPoint: item["name"].toLowerCase().includes("assembly")
            };
          }
        }
      }
    }
    this.setState({
      areas: areas
    });
  }

  tagStateCallback = (err:object, payload:{ [index: string]: { [index: string]: any; } }) => {
    let tagCache:TagState[] = [];
    let nowTimestamp = new Date().getTime();
    for (let [serialNumber, data] of Object.entries(payload)) {
      let timestamp:Date = new Date(data["timestamp"]);
      tagCache.push({
        serialNumber: parseInt(serialNumber),
        timestamp: timestamp,
        name: data["name"],
        age: Math.floor((nowTimestamp-timestamp.getTime())/1000),
        areas: data["areas"],
        lastAssemblyPoint: this.isEvacuated(0, data["areas"], timestamp),
        signalLost: data["signalLost"],
        powerSave: data["powerSave"]
      })
    }
    this.sortTags(tagCache);
    this.setState({
      tagCache: tagCache,
      appStarted: true
    });
  };
  
  tagDiffCallback = (err:object, payload:{ [index: string]: { [index: string]: any; } }) => {
    let tagCache:TagState[] = [...this.state.tagCache];
    let nowTimestamp = new Date().getTime();
    for (let serialNumber in payload.tags) {
      let tagCacheItem:TagState = {
        serialNumber: 0,
        timestamp: new Date(),
        name: "",
        age: 0,
        areas: [],
        lastAssemblyPoint: 0,
        signalLost: false,
        powerSave: false
      };
      for (let tagCacheRow of tagCache) {
        if (tagCacheRow.serialNumber === parseInt(serialNumber)){
          tagCacheItem = tagCacheRow;
          break;
        }
      }
      for (let key in payload.tags[serialNumber]){
        let newValue = payload.tags[serialNumber][key];
        switch(key){
          case "name": {
            tagCacheItem.name = newValue;
            break;
          }
          case "areas": {
              tagCacheItem.areas = newValue;
              break;
          }
          case "signalLost": {
            tagCacheItem.signalLost = newValue;
            break;
          }
          case "powerSave": {
            tagCacheItem.powerSave = newValue;
            break;
          }
        }
      }
      if (!tagCacheItem.signalLost && !tagCacheItem.powerSave) {
        tagCacheItem.timestamp = new Date();
        tagCacheItem.age = 0;
      }
      tagCacheItem.lastAssemblyPoint = this.isEvacuated(tagCacheItem.lastAssemblyPoint, tagCacheItem.areas, tagCacheItem.timestamp);
    }
    this.sortTags(tagCache);
    this.setState({
      tagCache: tagCache
    });
  };


  // Before the component mounts, we initialise our state
  componentWillMount() {
    this.setState({
      tagCache: [],
      appStarted: false,
      evacuationStarted: null,
      selectedAssemblyPoint: 0,
      areas: [],
      channel: null
    });
  }

  // After the component did mount, we set the state each second.
  componentDidMount() {
    setInterval(() => this.updateAge(), 1000);
  }
  render() {
    const assemblyPointOptions = [{
      label: <span>All</span>,
      value: 0
    }];
    for (let area in this.state.areas){
      if (!this.state.areas[area].evacuationPoint) continue;
      assemblyPointOptions.push({
        label: <span>{this.state.areas[area].name}</span>,
        value: parseInt(area)
      });
    }

    //const initialSelectedIndex = options.findIndex(({value}) => value === "bar");

    let content;
    if (!this.state.appStarted) {
      content = (<SignIn callback={this.connectToNoccela} hasChannel={this.state.channel} />)
    } else if(this.state.evacuationStarted === null){
      content = (<button onClick={this.startEvacuation}>Start evacuation</button>);
    } else {
      let minimumDate = new Date(this.state.evacuationStarted);
      minimumDate.setHours(minimumDate.getHours() - maxHoursBeforeEvacuation);

      let evacuated = 0;
      let seen = 0;
      for (let row of this.state.tagCache) {
        if (row.lastAssemblyPoint !== 0) evacuated++;
        else if (row.timestamp > minimumDate) seen++;
      }

      content = (<>
      <h2>{evacuated} person(s) registered at an assembly point after evacuation started {DateService.formatDate(this.state.evacuationStarted)}</h2>
      <div className="assembly-point-selector-wrapper"style={{maxWidth: assemblyPointOptions.length*200}} >
        <SwitchSelector
            onChange={this.selectedAssemblyPointChanged}
            options={assemblyPointOptions}
            backgroundColor={"#f5f5f5"}
            selectedBackgroundColor={"#f9eb0a"}
        />
    </div>
      <TagTable tags={this.state.tagCache} evacuated={true} availableAreas={this.state.areas} selectedAssemblyPoint={this.state.selectedAssemblyPoint} minimumDate={minimumDate} />
			<h2>{seen} person(s) seen {maxHoursBeforeEvacuation} hours before evacuation, but has not been registered to any of the assembly points</h2>
      <TagTable tags={this.state.tagCache} evacuated={false} availableAreas={this.state.areas} minimumDate={minimumDate} />
      </>);
    }
    
    return (
      <div className="App">
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Noccela Safety Management</h1>
        {content}
        </header>
       </div>
    );
  }
}

export default App;